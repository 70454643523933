define("discourse/plugins/discourse-events/discourse/raw-templates/connectors/topic-list-main-link-bottom/topic-list-event", ["exports", "discourse-common/lib/raw-handlebars", "discourse-common/lib/raw-templates"], function (_exports, _rawHandlebars, _rawTemplates) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let template = (0, _rawHandlebars.template)({
    "1": function (container, depth0, helpers, partials, data) {
      var stack1,
        alias1 = depth0 != null ? depth0 : container.nullContext || {},
        alias2 = container.escapeExpression,
        lookupProperty = container.lookupProperty || function (parent, propertyName) {
          if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
            return parent[propertyName];
          }
          return undefined;
        };
      return "  <div class=\"topic-list-item-event\">\n    <div class=\"topic-list-item-event-time\">\n      <a\n        href=" + alias2(lookupProperty(helpers, "get").call(alias1, "context.topic.lastUnreadUrl", {
        "name": "get",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 5,
            "column": 13
          },
          "end": {
            "line": 5,
            "column": 44
          }
        }
      })) + "\n        class=\"" + alias2(lookupProperty(helpers, "get").call(alias1, "context.topic.topicListItemClasses", {
        "name": "get",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 6,
            "column": 15
          },
          "end": {
            "line": 6,
            "column": 53
          }
        }
      })) + "\"\n      >\n        " + alias2((lookupProperty(helpers, "event-label") || depth0 && lookupProperty(depth0, "event-label") || container.hooks.helperMissing).call(alias1, "context.topic.event", {
        "name": "event-label",
        "hash": {
          "list": "true"
        },
        "hashTypes": {
          "list": "StringLiteral"
        },
        "hashContexts": {
          "list": depth0
        },
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 8,
            "column": 8
          },
          "end": {
            "line": 8,
            "column": 55
          }
        }
      })) + "\n      </a>\n    </div>\n" + ((stack1 = lookupProperty(helpers, "if").call(alias1, "context.siteSettings.events_rsvp", {
        "name": "if",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "fn": container.program(2, data, 0),
        "inverse": container.noop,
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 11,
            "column": 4
          },
          "end": {
            "line": 23,
            "column": 11
          }
        }
      })) != null ? stack1 : "") + "  </div>\n";
    },
    "2": function (container, depth0, helpers, partials, data) {
      var stack1,
        lookupProperty = container.lookupProperty || function (parent, propertyName) {
          if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
            return parent[propertyName];
          }
          return undefined;
        };
      return (stack1 = lookupProperty(helpers, "if").call(depth0 != null ? depth0 : container.nullContext || {}, "context.topic.event.rsvp", {
        "name": "if",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "fn": container.program(3, data, 0),
        "inverse": container.noop,
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 12,
            "column": 6
          },
          "end": {
            "line": 22,
            "column": 13
          }
        }
      })) != null ? stack1 : "";
    },
    "3": function (container, depth0, helpers, partials, data) {
      var alias1 = depth0 != null ? depth0 : container.nullContext || {},
        alias2 = container.escapeExpression,
        lookupProperty = container.lookupProperty || function (parent, propertyName) {
          if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
            return parent[propertyName];
          }
          return undefined;
        };
      return "        <div class=\"topic-list-item-event-rsvp\">\n          <a\n            href=" + alias2(lookupProperty(helpers, "get").call(alias1, "context.topic.lastUnreadUrl", {
        "name": "get",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 15,
            "column": 17
          },
          "end": {
            "line": 15,
            "column": 48
          }
        }
      })) + "\n            class=\"" + alias2(lookupProperty(helpers, "get").call(alias1, "context.topic.topicListItemClasses", {
        "name": "get",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 16,
            "column": 19
          },
          "end": {
            "line": 16,
            "column": 57
          }
        }
      })) + "\"\n          >\n            <span>" + alias2(lookupProperty(helpers, "get").call(alias1, "context.topic.event_going_total", {
        "name": "get",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 18,
            "column": 18
          },
          "end": {
            "line": 18,
            "column": 53
          }
        }
      })) + "</span>\n            <span>" + alias2((lookupProperty(helpers, "i18n") || depth0 && lookupProperty(depth0, "i18n") || container.hooks.helperMissing).call(alias1, "event_rsvp.going.label", {
        "name": "i18n",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "types": ["StringLiteral"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 19,
            "column": 18
          },
          "end": {
            "line": 19,
            "column": 51
          }
        }
      })) + "</span>\n          </a>\n        </div>\n";
    },
    "compiler": [8, ">= 4.3.0"],
    "main": function (container, depth0, helpers, partials, data) {
      var stack1,
        lookupProperty = container.lookupProperty || function (parent, propertyName) {
          if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
            return parent[propertyName];
          }
          return undefined;
        };
      return (stack1 = lookupProperty(helpers, "if").call(depth0 != null ? depth0 : container.nullContext || {}, "context.topic.event", {
        "name": "if",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "fn": container.program(1, data, 0),
        "inverse": container.noop,
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 25,
            "column": 7
          }
        }
      })) != null ? stack1 : "";
    },
    "useData": true
  });
  (0, _rawTemplates.addRawTemplate)("javascripts/connectors/topic-list-main-link-bottom/topic-list-event", template, {
    core: false,
    pluginName: "discourse-events",
    hasModernReplacement: false
  });
  var _default = _exports.default = template;
});